import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import { PROFILE_INFO_API } from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";
import { UserRole } from "~/shared/config";

interface User {
  firstName: string;
  lastName: string;
  email: string;
  organizations: {
    id: string;
    userId: string;
    organizationId: string;
    role: string;
    organization: {
      id: string;
      name: string;
    };
  }[];
  role: string;
}

export default function Profile() {
  const [user, setUser] = useState<User | null>(null);
  const { accessToken, storeUser } = useAuthStore((state) => ({
    accessToken: state.accessToken,
    storeUser: state.user,
  }));
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);

  const formatName = (name: string) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (!accessToken) {
          return;
        }
        const url = `${PROFILE_INFO_API}/${storeUser?.userId}`;
        const response = await networkService.get<any>(url);
        setUser(response.data);
        console.log("USER DATA ", response.data);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    fetchProfile();
  }, [storeUser, accessToken]);

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? `${storeUser?.role === UserRole.Admin ? "ml-20" : "md:ml-20"}` : `${storeUser?.role === UserRole.Admin ? "ml-72" : "md:ml-72"}`} transition-all duration-300 pb-24`}>
      <PageHeader title="Profile" />
      <div className="mt-10 border-t border-gray-100">
        <dl>
          <div className="py-2 flex md:flex-row flex-col items-start">
            <dt className="font-semibold text-l leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Name
            </dt>
            <dd className="md:mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk border w-full md:w-auto rounded-lg md:py-0 md:text-left md:border-none md:rounded-none py-2 text-center">
              {user?.firstName
                ? `${formatName(user?.firstName)} ${formatName(user.lastName)}`
                : "N/A"}
            </dd>
          </div>
          <div className="py-2 flex md:flex-row flex-col items-start">
            <dt className="text-l font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Email
            </dt>
            <dd className="md:mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk border w-full md:w-auto rounded-lg md:py-0 md:text-left md:border-none md:rounded-none py-2 text-center">
              {user?.email || "N/A"}
            </dd>
          </div>
          <div className="py-2 flex md:flex-row flex-col items-start">
            <dt className="text-l font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Organization
            </dt>
            {user?.organizations[0] ? (
              <dd className="md:mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk border w-full md:w-auto rounded-lg md:py-0 md:text-left md:border-none md:rounded-none py-2 text-center">
                {user?.organizations[0].organization.name}
              </dd>
            ) : (
              <dd className="md:mt-1 text-sm leading-6 text-rose-600 font-space-grotesk border w-full md:w-auto rounded-lg md:py-0 md:text-left md:border-none md:rounded-none py-2 text-center">
                {"You are not currently a part of any organization."}
              </dd>
            )}
          </div>
          <div className="py-2 flex md:flex-row flex-col items-start">
            <dt className="text-l font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Password
            </dt>
            <dd className="md:mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk border w-full md:w-auto rounded-lg md:py-0 md:text-left md:border-none md:rounded-none py-2 text-center">
              <span>*********</span>
              <Link
                to="/profile/reset-password"
                className="hidden md:inline-block md:ml-2 text-indigo-600 underline hover:no-underline cursor-pointer md:mt-2">
                Update password
              </Link>
            </dd>
            <Link
              to="/profile/reset-password"
              className="md:hidden text-sm md:text-base text-indigo-600 underline hover:no-underline cursor-pointer mt-2">
              Update password
            </Link>
          </div>
        </dl>
      </div>

      <div className="mt-3 md:mt-12 flex flex-wrap gap-4">
        <Link
          to={`/profile/update-user`}
          className="py-2 px-4 md:py-2 md:px-6 lg:py-2 lg:px-8 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk w-full md:w-auto text-center md:text-left">
          Update Profile
        </Link>
        <Link
          to={`/logout`}
          className="py-2 px-4 md:py-2 md:px-6 lg:py-2 lg:px-8 rounded-full border bg-black-900 text-white-a700 text-sm sm:text-base md:text-base font-space-grotesk w-full md:w-auto text-center md:text-left md:hidden">
          Logout
        </Link>
      </div>
    </div>
  );
}

import { ToastContainer } from "react-toastify";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";

const ToastWrapper = () => {
  const [position, setPosition] = useState<"top-right" | "bottom-right">(
    window.innerWidth < 768 ? "bottom-right" : "top-right"
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setPosition("bottom-right");
      } else {
        setPosition("top-right");
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <ToastContainer
      position={position}
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      toastClassName="relative flex items-center p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer mb-20 md:mb-2 mt-5 md:mt-0"
      bodyClassName={() =>
        "flex items-center text-sm text-[#1D443C] font-space-grotesk block p-3 px-2"
      } // Text color set using Tailwind CSS class
      closeButton={true}
    />
  );
};

export default ToastWrapper;

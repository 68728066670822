import React, { useEffect, useState } from "react";

interface CarouselProps {
  images: string[];
  interval?: number;
}

const Carousel: React.FC<CarouselProps> = ({ images, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const switchImage = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const intervalId = setInterval(switchImage, interval);

    return () => clearInterval(intervalId);
  }, [images.length, interval]);

  return (
    <div className="relative w-full h-full min-h-[250px] md:min-h-[400px]">
      {images.map((src, index) => (
        <div
          key={index}
          className={`absolute flex justify-center inset-0 transition-all duration-1000 ease-in-out ${
            index === currentIndex
              ? "opacity-100 z-10 blur-0"
              : "opacity-0 z-0 blur-md"
          }`}
          style={{ transitionDuration: `${interval / 3}ms` }}>
          <img
            src={src}
            alt={`Slide ${index}`}
            className="w-full h-full object-contain object-center md:object-left-top max-h-[450px] md:ml-10"
          />
        </div>
      ))}
    </div>
  );
};

export default Carousel;

import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CardDescription } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import networkService from "~/services/network.service";
import {
  ADD_LICENSE,
  CHECK_ORGANIZATION,
  CREATE_ORGANIZATION,
  CREATE_SUBSCRIPTION,
  GET_PLANS,
  LOGIN_API,
  REGISTRATION_API,
  SERVICE_ID,
} from "~/shared/constants/api";
import { REGISTRATION_ERROR } from "~/shared/constants/errormessage";
import {
  BUTTON_TEXT_REGISTER,
  BUTTON_TEXT_REGISTERING,
} from "~/shared/constants/label";
import useAuthStore from "~/store/auth.store";
import { PlanId, PlanType, UserRole } from "~/shared/config";
import { handleError } from "~/shared/utils/errors.util";
import HeartFocusIcon from "~/shared/icon/heartfocus.icon";
import { AlertCircle } from "lucide-react";
import TabletIcon from "~/shared/icon/tablet.icon";

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const planId = searchParams.get("planId");
  const { user, setAuthState, updateUserInfo } = useAuthStore((state) => ({
    user: state.user,
    setAuthState: state.setAuthState,
    updateUserInfo: state.updateUserInfo,
  }));
  const [registrationError, setRegistrationError] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (!user) return;

    if (user?.role === UserRole.Admin) {
      navigate("/home");
    } else {
      navigate("/dashboard");
      // if (user?.organizationId) {
      //   navigate("/dashboard");
      // } else {
      //   navigate("/onboarding/organization");
      // }
    }
  }, []);

  const checkOrg = async (values: any) => {
    try {
      const organizationData = await networkService.post<any>(
        CHECK_ORGANIZATION,
        {
          name: values.organization,
        }
      );
      const isAvailableToUse = organizationData.data.orgNameInUse;
      if (isAvailableToUse) {
        toast.error("Organization name already in use");
        return false;
      }
      return !isAvailableToUse;
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };
  const createLicense = async function (
    subscriptionId: string,
    deviceId: string
  ) {
    try {
      // create license
      if (deviceId) {
        const licenseResponse = await networkService.post<any>(
          `${ADD_LICENSE}/${subscriptionId}/${deviceId}`,
          null
        );
        toast.success("License generated successfully.");
        return licenseResponse;
      } else {
        const licenseResponse = await networkService.post<any>(
          `${ADD_LICENSE}/${subscriptionId}`,
          null
        );
        toast.success("License generated successfully.");
        return licenseResponse;
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
      return null;
    }
  };

  const createSubscription = async function (
    organizationId: string,
    planId: string
  ) {
    try {
      const subscriptionResponse = await networkService.post<any>(
        `${CREATE_SUBSCRIPTION}/${organizationId}/${planId}`,
        {
          nickName: `Subscription to ${planId}`,
        }
      );
      if (subscriptionResponse) {
        toast.success(
          "Subscription fetched successfully. Generating license..."
        );
        return subscriptionResponse.data.subscription?.id;
      }
      return null;
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
      return null;
    }
  };

  const getFreePlanId = async () => {
    try {
      const serviceId = SERVICE_ID;
      if (serviceId) {
        const response = await networkService.get<any>(
          `${GET_PLANS}/${serviceId}/plans`
        );
        const plan = response.data.results.filter(
          (plan) => plan.isActive && plan.type === PlanType.Free
        );
        return plan[0]?.id;
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  const handleRegistration = async (values: any, { setSubmitting }: any) => {
    setSubmitting(true);
    try {
      const orgFound = await checkOrg(values);

      if (!orgFound) {
        return;
      }

      const response = await networkService.post<any>(REGISTRATION_API, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
      });

      if (response) {
        toast.success("User registered successfully");

        const loginResponse = await networkService.post<any>(LOGIN_API, {
          email: values.email,
          password: values.password,
        });

        setAuthState({
          accessToken: loginResponse.data.accessToken,
          user: {
            userId: loginResponse.data.userId,
            role: loginResponse.data.role,
            organizationId: "",
          },
        });

        const createOrganizationResponse = await networkService.post<any>(
          CREATE_ORGANIZATION,
          {
            name: values.organization,
            description: `${values.organization} description`,
          }
        );
        updateUserInfo({ organizationId: createOrganizationResponse.data?.id });
        if (createOrganizationResponse) {
          toast.success("Organization created successfully");
          if (
            planId === PlanId.freePlanId ||
            planId === null ||
            planId === undefined
          ) {
            const id = planId ? planId : await getFreePlanId();
            if (id) {
              const subscriptionResponse = await createSubscription(
                createOrganizationResponse?.data?.id,
                id
              );
              const licenseResponse = await createLicense(
                subscriptionResponse,
                null
              );

              if (licenseResponse) {
                user?.role === UserRole.Admin
                  ? navigate("/home")
                  : navigate(
                      `/dashboard?plan=${PlanId.freePlanId}&licenseId=${licenseResponse.data?.id}`
                    );
              }
            }
          }
        }

        // setOnboardState({ planId: planId });
        // if(planId){
        //   navigate("/add-probe/plan");
        // }else{
        //   navigate("/login");
        // }
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
      setRegistrationError(REGISTRATION_ERROR);
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    organization: Yup.string()
      .required("Organization is required")
      .min(3, "Organization must be at least 3 characters"),
    firstName: Yup.string()
      .required("First name is required")
      .min(3, "First name must be at least 3 characters"),
    lastName: Yup.string()
      .required("Last name is required")
      .min(3, "Last name must be at least 3 characters"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  return (
    <div className="flex min-h-screen font-space-grotesk">
      <div className="flex md:w-2/5 w-full flex-col items-center px-6 lg:pt-8 lg:pb-3 z-10 py-10">
        {/* header */}
        <div className="font-sans text-2xl font-bold leading-tight text-center bg-radial-gradient bg-clip-text">
          <HeartFocusIcon />
        </div>
        {/* form */}
        <div className="w-full xl:w-4/5 flex flex-col mt-8 lg:mt-4 2xl:mt-auto">
          <Formik
            initialValues={{
              organization: "",
              firstName: "",
              lastName: "",
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleRegistration}>
            {({ isSubmitting, errors, touched }) => (
              <Form className="flex flex-col gap-8 lg:gap-4 2xl:gap-8">
                {/* header */}
                <CardDescription className="text-black flex flex-col gap-2">
                  <span className="text-4xl font-bold">Welcome!</span>
                  <span className="block text-base font-normal">
                    Sign up to get a 6-month free license for HeartFocus
                    Education.
                  </span>
                </CardDescription>
                {/* Registration Error */}
                {registrationError && (
                  <p className="text-[#F87171] text-sm font-space-grotesk font-medium flex items-center gap-3 border border-[#F87171] px-4 py-3 rounded-lg bg-[#FEF2F2] -my-4">
                    <AlertCircle size={16} />
                    <span>{registrationError}</span>
                  </p>
                )}
                <div
                  className={`flex flex-col gap-4 2xl:gap-6 ${errors.email || errors.firstName || errors.lastName || errors.organization || errors.password ? "lg:gap-1" : "gap-4 lg:gap-4 2xl:gap-6"}`}>
                  {/* Organization Field */}
                  <div className="flex w-full flex-col gap-1 items-start">
                    <Label className="text-[#1B1B20] font-space-grotesk text-sm font-semibold mb-1">
                      Organization
                    </Label>
                    <p className="text-xs text-[#79797F] font-normal -mt-1 mb-1">
                      The organization name needs to be unique. Try adding
                      letters and numbers if needed.
                    </p>
                    <Field
                      name="organization"
                      type="text"
                      className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.organization && touched.organization ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                      placeholder="Enter the name of your organization"
                    />
                    <ErrorMessage
                      name="organization"
                      component="p"
                      children={(errorMessage) => (
                        <div className="text-[#F87171] text-xs flex gap-1 items-center">
                          <AlertCircle size={14} />
                          <span>{errorMessage}</span>
                        </div>
                      )}
                    />
                  </div>
                  <div className="flex gap-8 md:gap-3 flex-col md:flex-row">
                    {/* First Name Field */}
                    <div className="flex w-full flex-col items-start gap-1">
                      <Label className="text-[#1B1B20] font-space-grotesk text-sm font-semibold mb-1">
                        First Name
                      </Label>
                      <Field
                        name="firstName"
                        type="text"
                        className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.firstName && touched.firstName ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                        placeholder="Enter your First Name"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="p"
                        children={(errorMessage) => (
                          <div className="text-[#F87171] text-xs flex gap-1 items-center">
                            <AlertCircle size={14} />
                            <span>{errorMessage}</span>
                          </div>
                        )}
                      />
                    </div>

                    {/* Last Name Field */}
                    <div className="flex w-full flex-col items-start gap-1">
                      <Label className="text-[#1B1B20] font-space-grotesk text-sm font-semibold mb-1">
                        Last Name
                      </Label>
                      <Field
                        name="lastName"
                        type="text"
                        className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.lastName && touched.lastName ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                        placeholder="Enter your Last Name"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="p"
                        children={(errorMessage) => (
                          <div className="text-[#F87171] text-xs flex gap-1 items-center">
                            <AlertCircle size={14} />
                            <span>{errorMessage}</span>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  {/* Email Field */}
                  <div className="flex w-full flex-col gap-1 items-start">
                    <Label className="text-[#1B1B20] font-space-grotesk text-sm font-semibold mb-1">
                      Email
                    </Label>
                    <Field
                      name="email"
                      type="email"
                      className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.email && touched.email ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                      placeholder="Enter your email address"
                    />
                    <ErrorMessage
                      name="email"
                      component="p"
                      children={(errorMessage) => (
                        <div className="text-[#F87171] text-xs flex gap-1 items-center">
                          <AlertCircle size={14} />
                          <span>{errorMessage}</span>
                        </div>
                      )}
                    />
                  </div>

                  {/* Password Field */}
                  <div className="flex w-full flex-col items-start gap-1">
                    <Label className="text-[#1B1B20] font-space-grotesk text-sm font-semibold mb-1">
                      Password
                    </Label>
                    <p className="text-xs text-[#79797F] font-normal -mt-1 mb-1">
                      Must be at least 6 characters long.
                    </p>
                    <Field
                      name="password"
                      type="password"
                      className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.password && touched.password ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                      placeholder="Enter password"
                    />
                    <ErrorMessage
                      name="password"
                      component="p"
                      children={(errorMessage) => (
                        <div className="text-[#F87171] text-xs flex gap-1 items-center">
                          <AlertCircle size={14} />
                          <span>{errorMessage}</span>
                        </div>
                      )}
                    />
                  </div>

                  {/* Submit Button */}
                  <div className="flex w-full justify-center items-center bg-angular-gradient rounded-full p-0.5 mt-5">
                    <button
                      type="submit"
                      className="w-full font-semibold bg-white-a700 rounded-full p-1.5"
                      disabled={isSubmitting}>
                      {isSubmitting
                        ? BUTTON_TEXT_REGISTERING
                        : BUTTON_TEXT_REGISTER}
                    </button>
                  </div>

                  <div className="flex flex-col gap-3 mt-2 2xl:mt-6">
                    <hr className="w-full border-t border-gray-300" />
                    <div className="w-full justify-center">
                      <Link
                        to="/login"
                        className="text-[#000] font-space-grotesk text-[16px] md:text-sm font-normal leading-[24px]">
                        Already have an account?{" "}
                        <span className="font-semibold underline">Login</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* terms and conditions */}
        <div className="w-full xl:w-4/5 flex flex-col mt-auto 2xl:items-center">
          <p className="text-xs text-gray-700 text-center 2xl:w-4/5">
            By clicking on ‘register’ you agree to HeartFocus’s{" "}
            <a
              href="https://www.heartfocus.ai/portal-terms-conditions"
              className="text-gray-700 underline">
              Legal notices & terms of use
            </a>
            , and the{" "}
            <a
              className="text-gray-700 underline"
              href="https://www.heartfocus.ai/education-software-license-agreement">
              HeartFocus Education Software license agreement
            </a>
            .
          </p>
        </div>
      </div>
      {/* image */}
      <div className="w-3/5 h-screen hidden md:block relative overflow-hidden">
        <img
          src="/Gradient.png"
          alt="Background Image"
          className="object-cover w-full h-full rounded-br-[10rem]"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center z-10 md:py-12 2xl:py-0">
          <div className="text-4xl font-bold mb-2">
            We believe
            <div
              style={{
                background: "black",
                display: "inline-block",
                padding: "0.15rem 0.5rem",
                marginLeft: "0.5rem",
              }}>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#e3e1f5] via-[#d4d3f0] to-[#b3b1e7]">
                No Hearts Can Wait.
              </span>
            </div>
          </div>
          <p className="text-lg mb-6">
            Join us in bringing heart health to the whole world.
          </p>
          <TabletIcon />
          <p>
            Learn more about HeartFocus{" "}
            <a href="https://heartfocus.ai" className="underline">
              here.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CustomSidebar from "./sidebar/custom-sidebar";
import BottomNav from "./bottomNav";
import useAuthStore from "~/store/auth.store";
import { networkService } from "~/services";
import { LOGOUT_API, PROFILE_INFO_API } from "../constants/api";
import { useEffect, useState } from "react";
import useSidebarStore from "~/store/sidebar.store";
import { UserRole } from "../config";
import { handleError } from "../utils/errors.util";
import useWarningModalStore from "~/store/warning.store";
import { UserRound } from "lucide-react";
import { HomeIcon } from "lucide-react";

export default function SidebarLayout() {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [collapsed, setIsCollapsed] = useSidebarStore((state) => [
    state.collapsed,
    state.setCollapsed,
  ]);
  const [profile, setProfile] = useState<{
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    emailVerified: false;
  } | null>(null);

  const [user, setAuthState] = useAuthStore((state) => [
    state.user,
    state.setAuthState,
  ]);
  const [clearAllWarningState] = useWarningModalStore((state) => [
    state.clearAllWarningState,
  ]);
  useEffect(() => {
    if (!user) return;
    const fetchUserProfile = async () => {
      try {
        const response = await networkService.get<any>(
          `${PROFILE_INFO_API}/${user.userId}`
        );
        setProfile(response.data);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    fetchUserProfile();
  }, [user]);

  const handleLogout = async () => {
    try {
      await networkService.post(LOGOUT_API, {});
      setAuthState();
      clearAllWarningState();
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };
  return (
    <div
      className={`max-w-screen flex h-[100dvh] max-h-screen overflow-hidden md:flex-row ${user?.role === UserRole.Admin ? "" : "flex-col"}`}>
      {/* <Sidebar /> */}
      <div
        className={`${user?.role === UserRole.Admin ? "" : "hidden md:block"}`}>
        <CustomSidebar
          pathname={pathname}
          collapsed={collapsed}
          setIsCollapsed={setIsCollapsed}
          toProfileRoute="/profile"
          handleLogout={handleLogout}
          userProfile={profile}
          showInfoCard={!profile?.emailVerified}
          heroFullIcon={
            <div className="flex h-16 shrink-0 items-center mb-0 pt-2">
              <img
                alt="HeartFocus logo"
                src="/black.svg"
                className="h-8 w-auto"
                width={20}
                height={22}
              />
              {!collapsed && (
                <h3 className="ml-4 text-neutral-900 font-bold text-2xl leading-normal font-space-grotesk">
                  Portal
                </h3>
              )}
            </div>
          }
          isActiveClassName="bg-background-gradient"
          containerClassName="font-space-grotesk"
          collapseButtonClassName="bg-white-a700"
          heroCollapsedIcon={
            <img
              alt="HeartFocus logo"
              src="/black.svg"
              className="h-8 w-auto"
              width={20}
              height={22}
            />
          }
          navigationItems={[
            {
              to: "/dashboard",
              label: "Home",
              isActive: pathname.includes("/dashboard"),
              fullIcon: <img src="/menu/plans.svg" alt="Dashboard" />,
              navigationExclusiveToRole: [UserRole.Member],
              activeIcon: <img src="/menu/plans-active.svg" alt="Dashboard" />,
              collapsedIcon: <img src="/menu/plans.svg" alt="Dashboard" />,
            },
            {
              to: "/home",
              label: "Home",
              isActive: pathname.includes("/home"),
              fullIcon: <img src="/menu/plans.svg" alt="Plan" />,
              navigationExclusiveToRole: [UserRole.Admin],
              activeIcon: <img src="/menu/plans-active.svg" alt="Plan" />,
              collapsedIcon: <img src="/menu/plans.svg" alt="Plan" />,
            },
            {
              to: "/customers",
              label: "Customers",
              isActive: pathname.includes("/customers"),
              fullIcon: <img src="/menu/customers.svg" alt="Customers" />,
              navigationExclusiveToRole: [UserRole.Admin],
              activeIcon: (
                <img src="/menu/customers-active.svg" alt="Customers" />
              ),
              collapsedIcon: <img src="/menu/customers.svg" alt="Customers" />,
            },
            // {
            //   to: "/probes",
            //   label: "Probes",
            //   isActive: pathname.includes("/probes"),
            //   fullIcon: <img src="/menu/probes.svg" alt="Probes" />,
            //   navigationExclusiveToRole: [],
            //   activeIcon: <img src="/menu/probes-active.svg" alt="Probes" />,
            //   collapsedIcon: <img src="/menu/probes.svg" alt="Probes" />,
            // },
            {
              to: "/licenses",
              label: "Licenses",
              isActive:
                pathname.includes("/licenses") || pathname.includes("/probes"),
              fullIcon: <img src="/menu/license.svg" alt="Licenses" />,
              navigationExclusiveToRole: [],
              activeIcon: <img src="/menu/license.svg" alt="Licenses" />,
              collapsedIcon: <img src="/menu/license.svg" alt="Licenses" />,
            },
            // {
            //   to: "/payment",
            //   label: "Payment",
            //   isActive: pathname.includes("/payment"),
            //   fullIcon: <img src="/menu/payment.svg" alt="Payment" />,
            //   navigationExclusiveToRole: [],
            //   activeIcon: <img src="/menu/payment-active.svg" alt="Payment" />,
            //   collapsedIcon: <img src="/menu/payment.svg" alt="Payment" />,
            // },
            {
              to: "/manufacturer",
              label: "Manufacturer",
              isActive: pathname.includes("/manufacturer"),
              fullIcon: <img src="/menu/manufacturer.svg" alt="Manufacturer" />,
              navigationExclusiveToRole: [UserRole.Admin],
              activeIcon: (
                <img src="/menu/manufacturer-active.svg" alt="Manufacturer" />
              ),
              collapsedIcon: (
                <img src="/menu/manufacturer.svg" alt="Manufacturer" />
              ),
            },
            // {
            //   to: "/reports",
            //   label: "Reports",
            //   isActive: pathname.includes("/reports"),
            //   fullIcon: <img src="/menu/reports.svg" alt="Reports" />,
            //   navigationExclusiveToRole: [UserRole.Admin],
            //   activeIcon: <img src="/menu/reports-active.svg" alt="Reports" />,
            //   collapsedIcon: <img src="/menu/reports.svg" alt="Reports" />,
            // },
          ]}
        />
      </div>

      <div className="flex-grow overflow-auto transition-all duration-300">
        <Outlet />
      </div>

      <div
        className={`${user?.role === UserRole.Admin ? "hidden" : "md:hidden"}`}>
        <BottomNav
          isActiveClassName="bg-background-gradient"
          navigationItems={[
            {
              to: "/dashboard",
              label: "Home",
              isActive: pathname.includes("/dashboard"),
              fullIcon: <HomeIcon />,
              navigationExclusiveToRole: [UserRole.Member],
              activeIcon: <HomeIcon />,
            },
            {
              to: "/licenses",
              label: "Licenses",
              isActive:
                pathname.includes("/licenses") || pathname.includes("/probes"),
              fullIcon: <img src="/menu/license.svg" alt="Licenses" />,
              navigationExclusiveToRole: [],
              activeIcon: <img src="/menu/license.svg" alt="Licenses" />,
            },
            {
              to: `/profile/${user?.userId}`,
              label: "Profile",
              isActive: pathname.includes("/profile"),
              fullIcon: <UserRound strokeWidth={1} />,
              navigationExclusiveToRole: [UserRole.Admin, UserRole.Member],
              activeIcon: <UserRound strokeWidth={2} />,
            },
          ]}
          pathname={pathname}
          setIsCollapsed={setIsCollapsed}
          handleLogout={handleLogout}
        />
      </div>
    </div>
  );
}

import React, { useState, useCallback } from "react";
import { UserRole } from "../config";
import clsx from "clsx";
import { Link } from "react-router-dom";
import useAuthStore from "~/store/auth.store";

// Define the types for BottomNavItem and BottomNavProps
export type BottomNavItem = {
  to: string;
  label: string;
  isActive: boolean;
  fullIcon: JSX.Element;
  activeIcon?: JSX.Element;
  navigationExclusiveToRole: UserRole[];
  subRoutes?: {
    label: string;
    isActive: boolean;
    onClick: () => void;
  }[];
};

export type ProfileRequiredProps<T> = {
  profileSection?: never;
  userProfile: T;
  handleLogout: () => void;
  profileClassName?: string;
  profileRouteActiveString?: string;
};

export type BottomNavProps<T> = {
  pathname: string;
  setIsCollapsed: (collapsed: boolean) => void;
  navigationItems: BottomNavItem[];
  isActiveClassName: string;
  handleLogout: () => void;
};

const BottomNav: React.FC<BottomNavProps<any>> = ({
  pathname,
  setIsCollapsed,
  navigationItems,
  isActiveClassName,
  handleLogout,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [user] = useAuthStore((state) => [state.user]);

  const toggleCollapse = useCallback(() => setCollapsed((prev) => !prev), []);

  const filterNavItems = (items: BottomNavItem[]) => {
    return items.filter((item) => {
      if (item.navigationExclusiveToRole.length === 0) return true;
      return item.navigationExclusiveToRole.some((role) =>
        user?.role.includes(role)
      );
    });
  };

  const filteredNavItems = filterNavItems(navigationItems);

  return (
    <div
      className="fixed bottom-0 w-full z-40 bg-white-a700"
      style={{ boxShadow: "0px 0px 0px white, 0em -3px 0.8em #c9c6c5" }}>
      <div className="flex items-center">
        {filteredNavItems.map((item) => (
          <div
            key={`${item.label}-${item.to}`}
            className={clsx(
              "w-full flex justify-center items-center",
              item.isActive ? isActiveClassName : "bg-white"
            )}>
            <Link to={item.to} className="flex flex-col items-center gap-2 p-2">
              <div className="">
                {item.isActive ? item.activeIcon : item.activeIcon}
              </div>
              <span className="text-sm">{item.label}</span>
            </Link>

            {item.subRoutes && collapsed && (
              <div className="flex flex-col ml-4">
                {item.subRoutes.map((subRoute, index) => (
                  <button
                    key={index}
                    className="py-1 text-xs"
                    onClick={subRoute.onClick}>
                    {subRoute.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomNav;

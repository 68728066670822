import { useNavigate } from "react-router-dom";
import { WarningIcon } from "../icon/warning-icon";
import { getFullDate } from "../utils/helper.util";

interface LicenseCardProps {
  licenseInfo: any;
  probeInfo: any;
  user?: any;
}

const LicenseCard: React.FC<LicenseCardProps> = ({
  licenseInfo,
  probeInfo,
  user,
}) => {
  const navigate = useNavigate();
  const probe = probeInfo.find((probe) => probe.id === licenseInfo?.device?.id);
  return (
    <div className="border shadow-md rounded-[10px] p-6 md:py-7 md:px-8 font-space-grotesk md:min-h-[250px] md:max-w-[1000px]">
      {/* license info */}
      <section className="flex flex-col gap-4 pb-3">
        <h1 className="flex justify-between font-bold text-2xl">
          <span>
            {licenseInfo?.subscription?.plan?.name ??
              `License-${licenseInfo?.id}`}
          </span>
          <span className="bg-[#F2FBF8] border border-[#A9E6D3] text-[#4BB69C] text-sm font-medium px-2 py-1.5 rounded-sm ml-auto">
            Active
          </span>
        </h1>
        <div className="flex md:flex-row flex-col justify-between text-zinc-600 font-normal mt-2">
          <span className="text-left flex gap-1 max-w-[300px] text-wrap">
            Id: {licenseInfo?.id}
          </span>
          <span className="mt-2 md:mt-0 md:text-right">
            <p>Expires on</p>
            {licenseInfo?.expiryDate
              ? getFullDate(licenseInfo?.expiryDate)
              : "Not Available"}
          </span>
        </div>
      </section>
      {/* probe info */}
      <section className="border-t min-h-[100px] pt-5">
        {licenseInfo?.device ? (
          <div className="flex flex-col justify-between min-h-[80px]">
            <div className="flex flex-col gap-2 font-medium text-base">
              <p>
                {`${probe?.nickName} attached (${probe?.model?.manufacturer?.name}, ${probe?.model?.modelName})`}
              </p>
              <p className="font-normal text-xs text-gray-500">{`Serial Number : ${probe?.externalDeviceId}`}</p>
            </div>
            <div className="flex md:flex-row flex-col mt-4 md:mt-0 md:ml-auto gap-2 md:gap-4">
              <button
                className="text-center py-2 px-6 rounded-full bg-white-a700 text-gray-900 text-base border border-gray-300"
                onClick={() => {
                  navigate(
                    `/probes/update-probe-details/${user.organizationId}/${probe.id}`
                  );
                }}>
                Edit Probe
              </button>
              <button
                className="text-center py-2 px-6 rounded-full bg-gray-900 text-white-a700 text-base border border-black-900"
                onClick={() => {
                  navigate(`/probes/${probe.id}`);
                }}>
                View Details
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-between min-h-[80px]">
            <div className="flex gap-2">
              <WarningIcon />
              <span className="text-[#E27D55]">No probe attached</span>
            </div>
            <div className="ml-auto">
              <button
                className="text-center py-2 px-6 rounded-full bg-gray-900 text-white-a700 text-base mt-auto"
                onClick={() =>
                  navigate(
                    `/probes/update-probe-details/${user.organizationId}/new?licenseId=${licenseInfo?.id}`
                  )
                }>
                Attach Probe
              </button>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default LicenseCard;

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import { PROFILE_INFO_API } from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";
import { UserRole } from "~/shared/config";

export default function Update() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const accessToken = useAuthStore((state) => state.accessToken);
  const user = useAuthStore((state) => state.user);
  const userId = user?.userId;
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);

  useEffect(() => {
    if (!userId || !accessToken) {
      return;
    }
    const fetchUserDetails = async () => {
      try {
        const response = await networkService.get<any>(
          `${PROFILE_INFO_API}/${userId}`
        );
        setUserData(response.data);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    fetchUserDetails();
  }, [userId, accessToken]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    if (!userId || !accessToken) return;

    const updateData = {
      email: userData.email,
      currentPassword: values.currentPassword,
      newPassword: values.newPassword || undefined,
    };

    try {
      await networkService.put<any>(
        `${PROFILE_INFO_API}/${userId}`,
        updateData
      );
      toast.success("User updated successfully");
      navigate(-1);
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .notOneOf(
        [Yup.ref("currentPassword")],
        "New password must be different from the current password"
      )
      .required("New password is required"),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm new password is required"),
  });

  return (
    <div
      className={`flex flex-col flex-grow px-8 py-5 overflow-hidden ${collapsed ? `${user?.role === UserRole.Admin ? "ml-20" : "md:ml-20"}` : `${user?.role === UserRole.Admin ? "ml-72" : "md:ml-72"}`} transition-all duration-300 pb-24`}>
      <PageHeader title="Profile" showBack />

      <div className="sm:px-0 mt-4">
        <h3 className="text-normal md:text-xl font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Profile &gt; Reset Password
        </h3>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={changePasswordSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div className="mt-4 space-y-4">
              <div>
                <label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-2">
                  Current Password
                </label>
                <div className="md:w-1/2">
                  <Field
                    name="currentPassword"
                    type="password"
                    placeholder="Enter current password"
                    className="md:mt-2 px-4 py-2 w-full rounded-[1rem] md:rounded-2xl border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                  />
                  <ErrorMessage
                    name="currentPassword"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>

              <div>
                <label className=" text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-2">
                  New Password
                </label>
                <div className="md:w-1/2">
                  <Field
                    name="newPassword"
                    type="password"
                    placeholder="Enter new password"
                    className="md:mt-2 px-4 py-2 w-full rounded-[1rem] md:rounded-2xl border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>

              <div>
                <label className=" text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-2">
                  Confirm New Password
                </label>
                <div className="md:w-1/2">
                  <Field
                    name="confirmNewPassword"
                    type="password"
                    placeholder="Confirm new password"
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    className="md:mt-2 px-4 py-2 w-full rounded-[1rem] md:rounded-2xl border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                  />
                  <ErrorMessage
                    name="confirmNewPassword"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 md:mt-12 flex flex-wrap gap-4">
              <button
                className="py-2 px-4 md:py-2 md:px-6 lg:py-2 lg:px-8 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk w-full md:w-auto text-center md:text-left"
                type="submit"
                disabled={isSubmitting}>
                {isSubmitting ? "Updating..." : "Update"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CardDescription } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { IError } from "~/interfaces/shared.interface";
import { UPDATE_PASSWORD } from "~/shared/constants/api";
import axios, { AxiosError } from "axios";
import { handleError } from "~/shared/utils/errors.util";
import HeartFocusIcon from "~/shared/icon/heartfocus.icon";
import TabletIcon from "~/shared/icon/tablet.icon";
import { AlertCircle } from "lucide-react";

const UpdatePassword: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const changePassword = async (password: string) => {
    if (!token) return;
    try {
      const response = await axios.post(`${UPDATE_PASSWORD}/${token}`, {
        newPassword: password,
      });
      if (response) {
        navigate("/login");
        toast.success("Password Updated Successfully");
      }
    } catch (err: any) {
      const error = err as AxiosError<IError>;
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  return (
    <div className="flex min-h-screen font-space-grotesk">
      <div className="flex md:w-2/5 w-full flex-col items-center px-6 lg:px-8 z-10 py-10">
        <div className="font-sans text-2xl font-bold leading-tight text-center bg-radial-gradient bg-clip-text">
          <HeartFocusIcon />
        </div>

        <div className="w-full xl:w-4/5 flex flex-col mt-auto">
          <Formik
            enableReinitialize
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              changePassword(values.password);
            }}>
            {({ handleChange, values, errors, touched }) => (
              <Form className="flex flex-col gap-12">
                <CardDescription className="text-black flex flex-col gap-2">
                  <span className="text-4xl font-bold">Set new password</span>
                  <span className="block text-base font-normal">
                    Please enter a new password for your account.
                  </span>
                </CardDescription>
                <div className="flex flex-col gap-6">
                  <div className="flex w-full flex-col gap-1 items-start">
                    <Label className="text-[#1B1B20] font-space-grotesk text-base font-semibold leading-normal mb-1">
                      New Password
                    </Label>
                    <div className="w-full">
                      <Field
                        name="password"
                        type="password"
                        className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.password && touched.password ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                        placeholder="Enter the new password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        children={(errorMessage) => (
                          <div className="text-[#F87171] text-xs mt-2 flex gap-1 items-center">
                            <AlertCircle size={14} />
                            <span>{errorMessage}</span>
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-1 items-start">
                    <Label className="text-[#1B1B20] font-space-grotesk text-base font-semibold leading-normal mb-1">
                      Confirm New Password
                    </Label>
                    <div className="w-full">
                      <Field
                        name="confirmPassword"
                        type="password"
                        className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.confirmPassword && touched.confirmPassword ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                        placeholder="Confirm the new password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        children={(errorMessage) => (
                          <div className="text-[#F87171] text-xs mt-2 flex gap-1 items-center">
                            <AlertCircle size={14} />
                            <span>{errorMessage}</span>
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full justify-center items-center bg-angular-gradient rounded-full p-0.5 mt-5">
                    <button
                      type="submit"
                      className="w-full font-semibold bg-white-a700 rounded-full p-1.5">
                      Change Password
                    </button>
                  </div>

                  <div className="flex flex-col gap-5 mt-5">
                    <hr className="w-full border-t border-gray-300" />
                    <div className="w-full justify-center">
                      <Link
                        to="/login"
                        className="text-[#000] font-space-grotesk text-[16px] md:text-sm font-normal leading-[24px]">
                        Don't want to reset password?{" "}
                        <span className="font-semibold underline">Login</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* terms and conditions */}
        <div className="w-full xl:w-4/5 flex flex-col mt-auto 2xl:items-center">
          <p className="text-xs text-gray-700 text-center 2xl:w-1/2">
            By continuing, you agree to Heartfocus's{" "}
            <span className="inline-block">
              <a
                href="https://www.heartfocus.ai/portal-terms-conditions"
                className="text-gray-700 hover:underline">
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://www.heartfocus.ai/education-privacy-policy"
                className="text-gray-700 hover:underline">
                Privacy Policy
              </a>
              .
            </span>
          </p>
        </div>
      </div>

      {/* image */}
      <div className="w-3/5 h-screen hidden md:block relative overflow-hidden">
        <img
          src="/Gradient.png"
          alt="Background Image"
          className="object-cover w-full h-full rounded-br-[10rem]"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center z-10 md:py-12 2xl:py-0">
          <div className="text-4xl font-bold mb-2">
            We believe
            <div
              style={{
                background: "black",
                display: "inline-block",
                padding: "0.15rem 0.5rem",
                marginLeft: "0.5rem",
              }}>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#e3e1f5] via-[#d4d3f0] to-[#b3b1e7]">
                No Hearts Can Wait.
              </span>
            </div>
          </div>
          <p className="text-lg mb-6">
            Join us in bringing heart health to the whole world.
          </p>
          <TabletIcon />
          <p>
            Learn more about HeartFocus{" "}
            <a href="https://heartfocus.ai" className="underline">
              here.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;

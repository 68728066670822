import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import networkService from "~/services/network.service";
import { CardDescription } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { UserRole } from "~/shared/config";
import { PROFILE_INFO_API, VERIFY_EMAIL_API } from "~/shared/constants/api";
import { BUTTON_TEXT_SEND } from "~/shared/constants/label";
import useAuthStore from "~/store/auth.store";
import { handleError } from "~/shared/utils/errors.util";
import TabletIcon from "~/shared/icon/tablet.icon";
import HeartFocusIcon from "~/shared/icon/heartfocus.icon";
import { AlertCircle } from "lucide-react";

const VerifyEmail: React.FC = () => {
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));
  const [userData, setUserData] = useState<any>();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    const getUser = async () => {
      try {
        const url = `${PROFILE_INFO_API}/${user.userId}`;
        const response = await networkService.get<any>(url);
        setUserData(response.data);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    getUser();
  }, [user]);

  useEffect(() => {
    if (!token) return;
    const verifyToken = async (token: string) => {
      try {
        const tokenVerificationResponse = networkService.post<any>(
          `${VERIFY_EMAIL_API}/${token}`,
          {}
        );
        if (tokenVerificationResponse) {
          user?.role === UserRole.Admin
            ? navigate("/home")
            : navigate("/dashboard");
          toast.success("Email verified successfully");
        }
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    verifyToken(token);
  }, [token, user, navigate]);

  const sendVerificationLink = async (email: string) => {
    try {
      const verificationResponse = await networkService.post<any>(
        `${PROFILE_INFO_API}/request-email-verification`,
        {
          email: email,
        }
      );
      if (verificationResponse) {
        user?.role === UserRole.Admin
          ? navigate("/home")
          : navigate("/dashboard");
        toast.success("Verification mail sent successfully");
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  return (
    <div className="flex min-h-screen font-space-grotesk">
      <div className="flex md:w-2/5 w-full flex-col items-center px-6 lg:px-8 z-10 py-10">
        <div className="font-sans text-2xl font-bold leading-tight text-center bg-radial-gradient bg-clip-text">
          <HeartFocusIcon />
        </div>
        {/* form */}
        <div className="mt-6 mx-auto w-full xl:w-4/5 flex flex-col">
          <Formik
            enableReinitialize
            initialValues={{ email: userData ? userData.email : "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              sendVerificationLink(values.email);
            }}>
            {({ handleChange, values, errors, touched }) => (
              <Form className="space-y-8">
                <CardDescription className="text-black flex flex-col gap-2">
                  <span className="text-4xl font-bold">Welcome!</span>
                  <span className="block text-base font-normal">
                    Please enter your email address to receive a verification
                    link.
                  </span>
                </CardDescription>
                <div className="flex flex-col gap-6">
                  <div className="flex w-full flex-col gap-1 items-start">
                    <Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-4">
                      Email
                    </Label>
                    <div className="w-full">
                      <Field
                        name="email"
                        type="email"
                        className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.email && touched.email ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                        placeholder="Enter your email address"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        children={(errorMessage) => (
                          <div className="text-[#F87171] text-xs mt-2 flex gap-1 items-center">
                            <AlertCircle size={14} />
                            <span>{errorMessage}</span>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex w-full justify-center items-center bg-angular-gradient rounded-full p-[2px] mt-5">
                      <button
                        type="submit"
                        className="w-full font-semibold bg-white-a700 rounded-full p-1.5">
                        {BUTTON_TEXT_SEND}
                      </button>
                    </div>

                    <div className="flex w-full justify-center items-center bg-black-900 text-white-a700 rounded-full p-0.5 mt-5">
                      <button
                        type="button"
                        className="border-none w-full py-2 rounded-[1rem]"
                        onClick={() => {
                          navigate("/login");
                        }}>
                        Back to Home
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="w-3/5 h-screen hidden md:block relative overflow-hidden">
        <img
          src="/Gradient.png"
          alt="Background Image"
          className="object-cover w-full h-full rounded-br-[10rem]"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center z-10 md:py-12 2xl:py-0">
          <div className="text-4xl font-bold mb-2">
            We believe
            <div
              style={{
                background: "black",
                display: "inline-block",
                padding: "0.15rem 0.5rem",
                marginLeft: "0.5rem",
              }}>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#e3e1f5] via-[#d4d3f0] to-[#b3b1e7]">
                No Hearts Can Wait.
              </span>
            </div>
          </div>
          <p className="text-lg mb-6">
            Join us in bringing heart health to the whole world.
          </p>
          <TabletIcon />
          <p>
            Learn more about HeartFocus{" "}
            <a href="https://heartfocus.ai" className="underline">
              here.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;

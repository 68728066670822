import { ErrorMessage, Field, Form, Formik } from "formik";
import { AlertCircle } from "lucide-react";
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import { CardDescription } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { UserRole } from "~/shared/config";
import { LOGIN_API, PROFILE_INFO_API } from "~/shared/constants/api";
import {
  ERROR_EMAIL,
  ERROR_EMAIL_INVALID,
  ERROR_PASSWORD,
  LOGIN_ERROR,
} from "~/shared/constants/errormessage";
import {
  BUTTON_TEXT_LOGGING_IN,
  BUTTON_TEXT_LOGIN,
} from "~/shared/constants/label";
import { EMAIL_REGEX } from "~/shared/constants/regex";
import HeartFocusIcon from "~/shared/icon/heartfocus.icon";
import TabletIcon from "~/shared/icon/tablet.icon";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";
import useOnboardStore from "~/store/onboard.store";

const LoginPage = () => {
  const navigate = useNavigate();
  const { user, setAuthState, updateUserInfo } = useAuthStore((state) => state);
  const [searchParams] = useSearchParams();
  const planId = searchParams.get("planId");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [setOnboardState] = useOnboardStore((state) => [state.setOnboardState]);

  React.useEffect(() => {
    if (!user) return;
    if (planId && user?.role !== UserRole.Admin) {
      setOnboardState({ planId: planId });
      navigate("/add-probe/plan");
      return;
    }

    if (user?.role === UserRole.Admin) {
      navigate("/home");
    } else {
      navigate("/dashboard");
      // if (user?.organizationId) {
      // 	navigate("/dashboard");
      // } else {
      // 	navigate("/onboarding/organization");
      // }
    }
  }, [user, navigate, planId, setOnboardState]);

  const getOrganizationDetails = async (userId: string) => {
    try {
      const response = await networkService.get<any>(
        `${PROFILE_INFO_API}/${userId}`
      );
      return response;
    } catch (error) {
      const err = error as IError;
      handleError({ error: err, message: err.message, level: "error" });
      err?.message && toast.error(err.message);
      return null;
    }
  };

  const handleLogin = async (values: { email: string; password: string }) => {
    setLoading(true);
    try {
      const response = await networkService.post<{
        data: {
          accessToken: string;
          userId: string;
          role: UserRole;
        };
      }>(LOGIN_API, values);

      setAuthState({
        accessToken: response.data.accessToken,
        user: {
          userId: response.data.userId,
          role: response.data.role,
          organizationId: "",
        },
      });

      toast.success("User logged in successfully");

      if (response.data.role === UserRole.Admin) {
        navigate("/home");
      } else {
        const userData = await getOrganizationDetails(response.data.userId);
        if (userData.data.organizations.length === 0) {
          navigate("/onboarding/organization");
        } else {
          updateUserInfo({
            organizationId: userData.data.organizations[0]?.organizationId,
          });
        }
      }
    } catch (error: any) {
      setErrorMessage(error.message || LOGIN_ERROR);
      handleError({ error: error, message: error.message, level: "error" });
    } finally {
      setLoading(false);
    }
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .required(ERROR_EMAIL)
      .matches(EMAIL_REGEX, ERROR_EMAIL_INVALID),
    password: Yup.string().required(ERROR_PASSWORD),
  });

  return (
    <div className="flex min-h-screen font-space-grotesk">
      <div className="flex md:w-2/5 w-full flex-col items-center px-6 lg:px-8 z-10 py-10">
        {/* header */}
        <div className="font-sans text-2xl font-bold leading-tight text-center bg-radial-gradient bg-clip-text">
          <HeartFocusIcon />
        </div>
        {/* form */}
        <div className="w-full xl:w-4/5 flex flex-col mt-auto">
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={handleLogin}>
            {({ isSubmitting, errors, touched }) => (
              <Form className="flex flex-col gap-12">
                {/* header */}
                <CardDescription className="text-black flex flex-col gap-2">
                  <span className="text-4xl font-bold">Welcome back!</span>
                  <span className="block text-base font-normal">
                    Glad to see you again.
                  </span>
                </CardDescription>
                {errorMessage && (
                  <p className="text-[#F87171] text-sm font-space-grotesk font-medium flex items-center gap-3 border border-[#F87171] px-4 py-3 rounded-lg bg-[#FEF2F2] -my-4">
                    <AlertCircle size={16} />
                    <span>{errorMessage}</span>
                  </p>
                )}
                <div className="flex flex-col gap-6">
                  {/* email */}
                  <div className="flex w-full flex-col gap-1 items-start">
                    <Label className="text-[#1B1B20] font-space-grotesk text-base font-semibold leading-normal mb-1">
                      Email
                    </Label>
                    <div className="w-full">
                      <Field
                        className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.email && touched.email ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                        type="email"
                        name="email"
                        placeholder="Enter your email address"
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        children={(errorMessage) => (
                          <div className="text-[#F87171] text-xs mt-2 flex gap-1 items-center">
                            <AlertCircle size={14} />
                            <span>{errorMessage}</span>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  {/* password */}
                  <div className="flex w-full flex-col items-start gap-1">
                    <div className="flex justify-between w-full mt-2 pr-2">
                      <Label className="text-[#1B1B20] font-space-grotesk text-base font-semibold leading-normal mb-1">
                        Password
                      </Label>
                      <Link
                        to="/forgot-password"
                        className="text-[#777777] text-sm underline hover:no-underline">
                        Forgot?
                      </Link>
                    </div>
                    <div className="w-full">
                      <Field
                        className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.password && touched.password ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                        type="password"
                        name="password"
                        placeholder="Enter password"
                      />
                      <ErrorMessage
                        name="password"
                        component="p"
                        children={(errorMessage) => (
                          <div className="text-[#F87171] text-xs mt-2 flex gap-1 items-center">
                            <AlertCircle size={14} />
                            <span>{errorMessage}</span>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex w-full justify-center items-center bg-angular-gradient rounded-full p-0.5 mt-5">
                  <button
                    type="submit"
                    className="w-full font-semibold bg-white-a700 rounded-full p-1.5"
                    disabled={loading || isSubmitting}>
                    {loading ? BUTTON_TEXT_LOGGING_IN : BUTTON_TEXT_LOGIN}
                  </button>
                </div>
                <div className="flex flex-col gap-5">
                  <hr className="w-full border-t border-gray-300" />
                  <div className="w-full justify-center">
                    <Link
                      to="/register"
                      className="text-[#000] font-space-grotesk text-[16px] md:text-sm font-normal leading-[24px]">
                      Don't have an account?{" "}
                      <span className="font-semibold underline">Sign up</span>
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* terms and conditions */}
        <div className="w-full xl:w-4/5 flex flex-col mt-auto 2xl:items-center">
          <p className="text-xs text-gray-700 text-center 2xl:w-1/2">
            By continuing, you agree to Heartfocus's{" "}
            <span className="inline-block">
              <a
                href="https://www.heartfocus.ai/portal-terms-conditions"
                className="text-gray-700 hover:underline">
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://www.heartfocus.ai/education-privacy-policy"
                className="text-gray-700 hover:underline">
                Privacy Policy
              </a>
              .
            </span>
          </p>
        </div>
      </div>
      {/* image */}
      <div className="w-3/5 h-screen hidden md:block relative overflow-hidden">
        <img
          src="/Gradient.png"
          alt="Background Image"
          className="object-cover w-full h-full rounded-br-[10rem]"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center z-10 md:py-12 2xl:py-0">
          <div className="text-4xl font-bold mb-2">
            We believe
            <div
              style={{
                background: "black",
                display: "inline-block",
                padding: "0.15rem 0.5rem",
                marginLeft: "0.5rem",
              }}>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#e3e1f5] via-[#d4d3f0] to-[#b3b1e7]">
                No Hearts Can Wait.
              </span>
            </div>
          </div>
          <p className="text-lg mb-6">
            Join us in bringing heart health to the whole world.
          </p>
          <TabletIcon />
          <p>
            Learn more about HeartFocus{" "}
            <a href="https://heartfocus.ai" className="underline">
              here.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
